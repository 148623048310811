<template>
  <span>
    <b-badge variant="primary" class="p-1 px-2 b-m clickable" title="Kundenzuweisung">
      <b-icon-person-fill scale="1.4" @click="onShowPo"/>
    </b-badge>
    <b-popover :target="'tt-customer-assigned-' + computer.id" triggers="manual" placement="left"
               :show.sync="popoverShowCustomerAssigned">
      <div>
        <span>Aktuelle Zuweisung:
          <span v-if="computer.customer_assigned">{{ computer.customer_assigned.kdnr }}</span>
          <span v-else>-</span>
        </span><br>
        <span>Neue Zuweisung:</span>
        <b-input size="sm" class="mb-2" v-model="popoverInput" :state="popoverInputState" @input="checkCustomer"/>
        <b-button @click="onClosePo" size="sm" :variant="variantDanger" class="mr-1">Abbrechen</b-button>
        <b-button @click="onUnassignPo" size="sm" :variant="variantDanger" class="mr-1"><b-icon-trash/></b-button>
        <b-button @click="onOkPo" size="sm" variant="primary" :disabled="!popoverInputState">Ok</b-button>
      </div>
    </b-popover>
  </span>
</template>

<script>
import _debounce from "lodash.debounce";
import {mapGetters} from "vuex";
import {getRequest, putRequest} from "@/modules/requests";

export default {
  name: "CustomerAssign",
  props: ['computer'],
  data() {
    return {
      hostnameControl: false,
      popoverShowCustomerAssigned: false,
      popoverInput: '',
      popoverInputState: null,
      customerId: null,
    }
  },
  methods: {
    refresh() {
      this.$emit('refresh')
    },
    onShowPo() {
      this.hostnameControl = false
      this.popoverShowCustomerAssigned = true
    },
    onClosePo() {
      this.popoverShowCustomerAssigned = false
    },
    async onOkPo() {
      const data = {
        customer_id: this.customerId
      }
      await putRequest('/pc/assign/' + this.computer.pc_uuid, data, this, null)
          .then(() => {
            this.$emit('refresh', true)
          })
      this.popoverShowCustomerAssigned = false
    },
    async onUnassignPo() {
      await putRequest('/pc/unassign/' + this.computer.pc_uuid, null, this, null)
          .then(() => {
            this.$emit('refresh', true)
          })
      this.popoverShowCustomerAssigned = false
    },
    checkCustomer: _debounce(async function () {
      await getRequest('/check-customer/' + this.popoverInput, null, this, null)
          .then((response) => {
            this.popoverInputState = true
            this.customerId = response.data.id
          })
          .catch(() => {
            this.popoverInputState = false
          })
    }, 500),
  },
  computed: {
    ...mapGetters(['userThemeId']),
    variantDanger() {
      if(this.userThemeId === 3) { return 'danger-deuteranopia' }
      return 'danger'
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
